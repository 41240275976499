<template>
  <p>Vul de gevonden cijfers in de juiste volgorde hieronder in (1 cijfer per hokje)</p>
  <div class="coordinaten">
  <form>
    N
    <input text v-model="entries[0]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(0) }" />
    <input text v-model="entries[1]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(1) }" />°
    <input text v-model="entries[2]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(2) }" />
    <input text v-model="entries[3]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(3) }" />'
    <input text v-model="entries[4]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(4) }" />
    <input text v-model="entries[5]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(5) }" />.
    <input text v-model="entries[6]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(6) }" />"
    <br />E
    <input text v-model="entries[7]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(7) }" />°
    <input text v-model="entries[8]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(8) }" />
    <input text v-model="entries[9]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(9) }" />'
    <input text v-model="entries[10]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(10) }" />.
    <input text v-model="entries[11]" maxlength="1" size="1"
    v-bind:class="{ correct: is_correct(11) }" />"
  </form>
  </div>
  <div v-if="all_correct">
    <p>Je hebt volgende coördinaten gevonden: <b>N50° 52' 48.5" E4° 43' 4.8"</b></p>
    <p>Waar bevinden deze coördinaten zich? (hint: Google is alwetend)</p>
    <p><b>SMS het antwoord naar 0472/77.63.21</b></p>
    <form>
      <label>Vul de code die je terug-krijgt (per SMS) hier in:</label>
      <input text maxlength="8" size="8" v-model="code"/>
    </form>
    <div v-show="code_correct">
      <p><b>Code correct!!</b></p>
      <p style="color:red;">
        Trek je iets leuk aan (tip: suggestie hangt aan de zijkant van de kast)
        en parkeer je je auto op de gevonden coördinaten??
      </p>
      <p style="color:red;">
        Vergeet geen sms'je te sturen met je ETA aan de parking! 😇
      </p>
      <p style="color:red;">
        Tot zo!!! ❤️
      </p>
      <p style="color:red;">
        PS I Doe schoenen aan waar je toch een kilometerje of 2 mee kan wandelen ;)
      </p>
      <p style="color:red;">
        PS II Neem misschien ook nog een jasje mee
      </p>
    </div>
  </div>
  <div v-else>
    <p>Je antwoord is nog niet (volledig) correct</p>
  </div>
</template>

<script>
export default {
  name: 'Coordinaten',
  emits: ['correct'],
  data() {
    return {
      entries: ['', '', '', '', '', '', '', '', '', '', '', ''],
      juiste_antwoord: ['5', '0', '5', '2', '4', '8', '5', '4', '4', '3', '4', '8'],
      code: '',
      juiste_code: '15022017',
    };
  },
  methods: {
    is_correct(i) {
      if (this.entries[i] === this.juiste_antwoord[i]) {
        return true;
      }

      return false;
    },
  },
  computed: {
    all_correct() {
      for (let i = 0; i < this.entries.length; i += 1) {
        if (this.entries[i] !== this.juiste_antwoord[i]) {
          return false;
        }
      }

      return true;
    },
    code_correct() {
      if (this.code === this.juiste_code) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
  .coordinaten {
    display: flex;
    justify-content: center;
  }
  .correct {
    border-color: green;
  }
  input:focus {
    outline: none;
  }
  label {
    margin-right: 5px;
  }
</style>
