<template>
  <div>
    <Round1 />
  </div>
</template>

<script>
// @ is an alias to /src
import Round1 from '@/components/Round1.vue';

export default {
  name: 'Home',
  components: {
    Round1,
  },
};
</script>
