<template>
  <div>
    <h1>Dag liefste Stefster &#60;3</h1>
    <p><em>A.k.a. Knappie-Wappie, a.k.a. Bollie-Wollie</em></p>
    <div v-show="ronde===1">
      <h2>Zin in een kleine speurtocht?</h2>
      <p v-if="!zin_speurtocht_ja">
        <img src="gilles.jpeg" height="200" /><br /><br />
        <a href="#/ronde1" v-on:click="zin_speurtocht_ja=true; zin_speurtocht_nee=false">Ja!</a>
        -
        <a href="#/try-again" v-on:click="zin_speurtocht_ja=false; zin_speurtocht_nee=true">Nee</a>
      </p>
      <div v-else>
        <p><b>Ja!</b> - Ik had het niet anders verwacht!</p>
        <p><img src="IMG_9151.JPG" width="225" /></p>
      </div>
    </div>
    <div v-show="zin_speurtocht_ja && ronde===1">
      <h2>Beantwoord onderstaande vragen</h2>
      <em>Tik tak tik tak tik tak..</em>
      <div v-if="correct_answers < 10">
        <p><b>Score:</b> {{ correct_answers }} / 10</p>
      </div>
      <div v-else>
        <p><span class="alles_juist">Alles juist!!</span> -
        <a href="#/ronde2" @click="ronde = 2">Ga verder</a></p>
      </div>
      <form>
        <Vraag
          v-bind:nummer="1"
          vraag="Wat is de luchthaven-code (IATA-formaat = 3 letters) van je eerste bestemming
          als buddy?"
          v-bind:antwoorden="['agp']"
          tip=""
          @correct="vragen_correct[0] = $event"
        />
        <Vraag
          v-bind:nummer="2"
          vraag="Wat is de volledige naam (voornaam + naam) van de captain op die vlucht?"
          v-bind:antwoorden="['gilles nelis']"
          tip="We zijn FaceBook-vrienden"
          @correct="vragen_correct[1] = $event"
        />
        <Vraag
          v-bind:nummer="3"
          vraag="Wat is naam van het mooiste vliegtuig ter wereld?"
          v-bind:antwoorden="['sunshine']"
          tip="Really?!"
          @correct="vragen_correct[2] = $event"
        />
        <Vraag
          v-bind:nummer="4"
          vraag="Wat is het lot-nummer van ons perceel in de Doornwijk?"
          v-bind:antwoorden="['177']"
          tip=""
          @correct="vragen_correct[3] = $event"
        />
        <Vraag
          v-bind:nummer="5"
          vraag="Wat is de registratie van de nieuwste aanwinst in de Skywings-vloot?
          (aangekomen in Antwerpen op 10 Juli 2021)"
          v-bind:antwoorden="['oo-skv']"
          tip="Zulk een event kan nooit geruisloos voorbij gaan op de sociale media"
          @correct="vragen_correct[4] = $event"
        />
        <Vraag
          v-bind:nummer="6"
          vraag="Hoeveel calorieën telt de Porterhouse steak in restaurant Logan's Roadhouse
          in Greenville, South Carolina?"
          v-bind:antwoorden="['1092']"
          tip="https://logansroadhouse.com/"
          @correct="vragen_correct[5] = $event"
        />
        <Vraag
          v-bind:nummer="7"
          vraag="Met welke hoeveelheid flaps landt een Boeing 737 normaal gezien?"
          v-bind:antwoorden="['30','flaps 30']"
          tip="Je favoriete t-shirt.."
          @correct="vragen_correct[6] = $event"
        />
        <Vraag
          v-bind:nummer="8"
          vraag="Wat is de naam van de favoriete frituur van 's lands beste radio-presentator?"
          v-bind:antwoorden="['conny & franky', 'frituur conny & franky', 'conny en franky',
          'frituur conny en franky']"
          tip=""
          @correct="vragen_correct[7] = $event"
        />
        <Vraag
          v-bind:nummer="9"
          vraag="Wat is de beste Queen-schijf?"
          v-bind:antwoorden="['under pressure']"
          tip="Voel je de druk al?"
          @correct="vragen_correct[8] = $event"
        />
        <Vraag
          v-bind:nummer="10"
          vraag="Hoeveel muur-tegels telt onze douche? (inclusief de onvolledige tegels)"
          v-bind:antwoorden="['63']"
          tip="Tellen maar!!"
          @correct="vragen_correct[9] = $event"
        />
      </form>
      <div v-show="correct_answers == 10">
        <p><span class="alles_juist">Alles juist!!</span> -
        <a href="#/ronde2" @click="ronde = 2">Ga verder</a></p>
      </div>
    </div>
    <div v-show="zin_speurtocht_ja && ronde===2">
      <h2>Klaar voor de 2e ronde??</h2>
      <p>Ready. Set. Go!</p>
      <p>Onder je hoofdkussen ligt een envelop, ga die halen!</p>
      <p v-if="!envelop"><a href="#/envelop" @click="envelop = true">Ik heb de envelop!</a></p>
      <p v-else><Coordinaten /></p>
    </div>
    <div v-show="zin_speurtocht_nee">
      <p>Try again..</p>
    </div>
  </div>
</template>

<script>
import Vraag from '@/components/Vraag.vue';
import Coordinaten from '@/components/Coordinaten.vue';

export default {
  name: 'Round1',
  components: {
    Vraag,
    Coordinaten,
  },
  props: {
    //
  },
  methods: {
    //
  },
  computed: {
    correct_answers() {
      let out = 0;
      for (let i = 0; i < this.vragen_correct.length; i += 1) {
        if (this.vragen_correct[i]) {
          out += 1;
        }
      }
      return out;
    },
  },
  data() {
    return {
      ronde: 1,
      zin_speurtocht_ja: false,
      zin_speurtocht_nee: false,
      vragen_correct: [false, false, false, false, false, false, false, false, false, false],
      envelop: false,
    };
  },
};
</script>

<style>
  .alles_juist {
    color: green;
    font-weight: 800;
    font-size: 24pt;
  }
  a:visited {
    color: blue;
  }
</style>
