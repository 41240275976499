<template>
<h1>Antwoorden:</h1>
  <div v-for="i in antwoorden" :key="i">
    <span v-show="i.value !==''">
      <b>Vraag {{ i.vraag }}:</b> {{ i.value }} <span v-show="i.correct">(Correct)</span>
    </span>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Verify',
  data() {
    return {
      antwoorden: [],
    };
  },
  components: {
    //
  },
  methods: {
    async get_answers() {
      try {
        const rest = await axios.get('http://koeke.duckdns.org:3000/antwoorden');
        this.antwoorden = rest.data;
        console.log(this.antwoorden);
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    setInterval(this.get_answers, 15000);
  },
};
</script>
