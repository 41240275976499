<template>
  <div class="vraag">
  <label>
    <b>Vraag {{ nummer }}:</b> {{ vraag }}
  </label>
  <!-- <input text v-model="antwoord" @blur="store_answer" /> -->
  <input text v-model="antwoord" />
  <p v-show="is_correct" class="correct">Correct!</p>
  </div>
  <div class="show_tip" v-show="tip.length != ''">
    <p class="tip" v-show="!show_tip"><a href="#/tip" @click="show_tip=!show_tip">
      Ik ben zwak, please be so kind en geef me een tip
    </a></p>
    <p v-show="show_tip" class="tip">Tip: <i>{{ tip }}</i></p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Vraag',
  emits: ['correct'],
  props: {
    nummer: Number,
    vraag: String,
    antwoorden: Array,
    tip: String,
  },
  data() {
    return {
      antwoord: '',
      show_tip: false,
      correct: false,
    };
  },
  methods: {
    async store_answer() {
      // console.log('test');
      try {
        await axios.post('http://koeke.duckdns.org:3000/antwoorden',
          {
            value: this.antwoord,
            ronde: 1,
            vraag: this.nummer,
            correct: this.is_correct,
          });
      } catch (e) {
        // console.error(e);
      }
    },
  },
  computed: {
    is_correct() {
      for (let i = 0; i < this.antwoorden.length; i += 1) {
        // Iterate over numeric indexes from 0 to 5, as everyone expects.
        if (this.antwoord.toLowerCase() === this.antwoorden[i]) {
          this.$emit('correct', true);
          return true;
        }
      }

      this.$emit('correct', false);
      return false;
    },
  },
};
</script>

<style scoped>
  label {
    margin-right: 5px;
  }
  .vraag {
    display: flex;
    margin-bottom: 10px;
  }
  .show_tip {
    display:flex;
    margin-bottom: 10px;
  }
  .tip {
    margin: 0px 0px 0px 10px;
  }
  .correct {
    margin: 0px 0px 0px 10px;
    color: green;
    font-weight: 800;
  }
</style>
